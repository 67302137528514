import {Component} from '@angular/core';
import {Store} from "@ngrx/store";
import {selectUser} from "../../../stores/auth/auth.selector";
import {Router} from "@angular/router";
import {getChats} from "../../../stores/chatbot/chatbot.selector";
import {requestChats} from "../../../stores/chatbot/chatbot.actions";
import { EPaths } from '../../enums/path.enums';
import { ERole } from '../../enums/role.enums';
import { IChat } from 'src/app/interfaces/IChat';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrl: './sidebar.component.scss'
})
export class SidebarComponent {
    user$ = this.store.select(selectUser)
    chats$ = this.store.select(getChats)
    type: string | null = null;
    showExpand: boolean;
    paths = EPaths;
    roleEnum = ERole;

    constructor(private store: Store, private router: Router) {
        this.store.dispatch(requestChats())
    }

    onSidenavHidden() {
        this.showExpand = true;
    }

    onSidenavShow() {
        this.showExpand = false;
    }

    startEditing(chat: IChat) {
        chat.isEditing = true;
        chat.newName = chat.messages[chat.messages.length - 1].message;
        setTimeout(() => {
          const input = document.getElementById('edit-input-' + chat._id) as HTMLInputElement;
          input?.focus();
        });
      }

      onInputChange(event: Event, chat: IChat) {
        const target = event.target as HTMLInputElement;
        if (target) {
          chat.newName = target.value;
        }
      }
    
      finishEditing(chat: IChat) {
        if (chat.newName && chat.newName.trim() !== '') {
          chat.messages[chat.messages.length - 1].message = chat.newName.trim();
          //this.chatService.updateChatName(chat.newName.trim()).subscribe();
        }
        chat.isEditing = false;
      }
    
      cancelEditing(chat: IChat) {
        chat.isEditing = false;
      }

    logout() {
        localStorage.removeItem('token')
        this.router.navigate(['/login'])
    }
}
