import {createFeatureSelector, createSelector} from "@ngrx/store";
import {ChatbotState} from "./chatbot.reducer";

export const selectChatbotState = createFeatureSelector<ChatbotState>('chatbot');

export const selectError = createSelector(
  selectChatbotState, (state: ChatbotState) => state.error
);

export const getSend = createSelector(
  selectChatbotState, (state: ChatbotState) => state.message
);

export const getChats = createSelector(
  selectChatbotState, (state: ChatbotState) => state.chats
);

