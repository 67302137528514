import {createAction, props} from "@ngrx/store";
import {IChat, IMessage} from "../../interfaces/IChat";

export const send = createAction('[Chatbot] Send',props<{index: string, query: string, chatId: string}>());
export const sendSuccess = createAction('[Chatbot] Send Success', props<{ chat: IChat }>());
export const sendFailure = createAction('[Chatbot] Send Failure', props<{ error: any }>());
export const sendUserMessage = createAction('[Chatbot] Send User Message', props<{ message: string, chatId: string, sender: 'user' | 'bot' }>());

export const requestChat = createAction('[Chatbot] Get',props<{chatId: string}>());
export const requestChatSuccess = createAction('[Chatbot] Get Success', props<{ chat: IChat }>());
export const requestChatFailure = createAction('[Chatbot] Get Failure', props<{ error: any }>());


export const requestChats = createAction('[Chatbot] Get chats');
export const requestChatsSuccess = createAction('[Chatbot] Get chats Success', props<{ chats: IChat[] }>());
export const requestChatsFailure = createAction('[Chatbot] Get chats Failure', props<{ error: any }>());
