import { EPaths } from "src/app/@core/enums/path.enums";
import { ERole } from "src/app/@core/enums/role.enums";
import { IPath } from "src/app/@core/interfaces/path.interfaces";



export const paths: IPath[] = [
    {
        id: 'user-management',
        label: 'User Management',
        absolutePath: '/dashboard/user-management',
        relativePath: EPaths.USER_MANAGEMENT,
        roles: [ERole.ADMIN, ERole.USER],
    }, {
        id: 'user-detail',
        label: 'User detail',
        absolutePath: '/dashboard/user-management/user-detail',
        relativePath: EPaths.USER_DETAIL,
        roles: [ERole.ADMIN],
    },
    {
        id: 'create-user',
        label: 'create user',
        absolutePath: '/dashboard/user-management/create-user',
        relativePath: EPaths.CREATE_USER,
        roles: [ERole.ADMIN],
    }
]

export const limitedPaths: IPath[] = [
    {
        id: 'user-management',
        label: 'User Management',
        absolutePath: '/dashboard/user-management',
        relativePath: EPaths.USER_MANAGEMENT,
        roles: [ERole.ADMIN],
    },
    {
        id: 'user-detail',
        label: 'User detail',
        absolutePath: '/dashboard/user-management/user-detail',
        relativePath: EPaths.USER_DETAIL,
        roles: [ERole.ADMIN],
    },
    {
        id: 'create-user',
        label: 'create user',
        absolutePath: '/dashboard/user-management/create-user',
        relativePath: EPaths.CREATE_USER,
        roles: [ERole.ADMIN],
    }
]