import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AlertService } from 'src/app/@shared/alert/alert.service';
import { AuthService } from '../../services/auth.service';
import { ApiAuthService } from '../../services/api/api-auth.service';

@Component({
  selector: 'chatbot-reset-passwprd',
  templateUrl: './reset-passwprd.component.html',
  styleUrl: './reset-passwprd.component.scss'
})
export class ResetPasswprdComponent {

  constructor(
    private store: Store,
    private alert: AlertService,
    private apiAuth:ApiAuthService,

  ) {}


  form = new FormGroup({
    email: new FormControl("", Validators.email)
  })
  
  reset(){
    console.log(this.form.value.email);
    this.apiAuth.sendEmail(this.form!.value.email!).subscribe(res=>{console.log(res)});
  }
}
