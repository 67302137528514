import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ILogin, IRegistration} from "./interfaces/api-auth.interface";
import {IResponseApi} from "../../../interfaces/IApi";
import {Observable, of} from "rxjs";
import {environment} from "../../../../environments/environment";
import {catchError} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ApiAuthService {
  headers = new HttpHeaders()

  constructor(private http: HttpClient) {
  }

  login(data: ILogin): Observable<IResponseApi<any>> {
    return this.http.post<IResponseApi<any>>(environment.url_api + '/auth/login', data, {headers: this.headers})
  }

  registration(user: IRegistration): Observable<IResponseApi<any>> {
    return this.http.post<IResponseApi<any>>(environment.url_api + '/auth/registration', user, {headers: this.headers})
  }

  getMe(): Observable<any>{
    return this.http.get<IResponseApi<any>>(environment.url_api + '/auth/getMe', {headers: this.headers})
  }

}
