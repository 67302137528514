import { createAction, props } from "@ngrx/store";
import { Instance, InstanceCreate } from "src/app/@core/services/api/interfaces/api-instance.interface";
import { GetTableData } from "src/app/@core/services/api/interfaces/api-utils.interface";

export const setParametersSelected = createAction('[Instances] Set Instance', props<{ instance: string , model:string}>());

export const sendInstance = createAction('[Instances] Send', props<{ instance: InstanceCreate }>());
export const sendSuccess = createAction('[Instances] Send Success', props<{ instance: any }>());
export const sendFailure = createAction('[Instances] Send Failure', props<{ error: any }>());

export const updateInstance = createAction('[Instances] update', props<{ instance: InstanceCreate }>());
export const updateSuccess = createAction('[Instances] update Success', props<{ instance: any }>());
export const updateFailure = createAction('[Instances] update Failure', props<{ error: any }>());

export const updateModel = createAction('[Chatbot] Update Model', props<{ chatId: string , model:string}>())
export const updateModelSucces = createAction('[Chatbot] Update Model Success', props<{  model: string }>());
export const updateModelFailure = createAction('[Chatbot] Update Model Failure', props<{ ModelUpdateError: any }>());

export const requestInstances = createAction('[Instances] Get Instances', props<{ request: GetTableData }>());
export const requestInstancesSuccess = createAction('[Instances] Get Instances Success', props<{ instances: any }>());
export const requestInstancesError = createAction('[Instances] Get Instances Error', props<{ error: any }>());

export const requestInstancesList = createAction('[InstancesList] Get InstancesList');
export const requestInstancesListSuccess = createAction('[InstancesList] Get InstancesList Success', props<{ instances: any }>());

export const deleteInstance = createAction('[Instances] Delete Instance', props<{ instanceId: string }>());




