<mdb-sidenav-layout>
  <mdb-sidenav #sidenav="mdbSidenav" [accordion]="false" [color]="'light'" [hidden]="false"
               [mode]="'side'" class="dashboard-sidenav__wrapper" (sidenavHidden)="onSidenavHidden()" (sidenavShow)="onSidenavShow()">
    <div class="dashboard-sidenav__wrapper">
      <div class="d-flex align-items-center justify-content-center py-3">
        <h4 class="m-0">Chatbot</h4>
        <button type="button" (click)="sidenav.hide()" onkeypress="sidenav.hide()" class="btn text-white shadow-none end-0 position-absolute" mdbRipple>
          <i class="fas fa-lg fa-arrow-left"></i>
        </button>
      </div>
      <hr class="m-0"/>

      <ul class="sidenav-menu dashboard-sidenav__wrapper__menu">
        <ng-container>
          <mdb-sidenav-item>
            <a class="sidenav-link" routerLink="colossus/chat/new" routerLinkActive="active">
              <i class="far fa-comments me-3"></i>
              <span>New chat</span>
            </a>
          </mdb-sidenav-item>

          <li>
            <hr class="my-0">
          </li>

          <mdb-sidenav-item *ngFor="let chat of (chats$ | async)">
            <a class="sidenav-link" [routerLink]="'colossus/chat/'+chat._id" routerLinkActive="active"
              (dblclick)="startEditing(chat)">
              <span *ngIf="!chat.isEditing">
                {{chat.messages[chat.messages.length -1].message | truncate: [20]}} <!-- TODO QUESTO SARà CHAT.NAME SICURAMENTE -->
              </span>
              <input mdbInput type="text" *ngIf="chat.isEditing" [value]="chat.newName!" (input)="onInputChange($event, chat)"
                (blur)="finishEditing(chat)" (keyup.enter)="finishEditing(chat)" (keyup.escape)="cancelEditing(chat)" [id]="'edit-input-' + chat._id" class="form-control"/>
            </a>
          </mdb-sidenav-item>

        </ng-container>
      </ul>
    </div>
  </mdb-sidenav>
  <mdb-sidenav-content #sidenavContent>

    <!-- Navbar-->
    <nav class="cb-navbar navbar navbar-expand-lg navbar-light bg-light p-0">
      <div class="container-fluid justify-content-between">
        <!-- Left elements -->
        <div class="d-flex">
          <!-- Brand -->
           @if (showExpand) {
            <button (click)="sidenav.show()" onkeypress="sidenav.show()" class="btn shadow-none cb-navbar__btn me-2" >
              <i class="fas fa-lg fa-arrow-right"></i>
            </button>
            <button  routerLink="colossus/chat/new" routerLinkActive="active" class="btn shadow-none cb-navbar__btn me-3" >
              <i class="fas fa-lg fa-plus"></i>
            </button>
           }

          <span class="d-flex align-items-center"><b>Welcome back: </b><em class="ms-1">{{(user$ | async)?.email}}</em></span>
        </div>
        <!-- Left elements -->

        <!-- Right elements -->
        <ul class="navbar-nav flex-row">
          <li *roles="[roleEnum.ADMIN]" class="nav-item me-3 me-lg-1">
            <a class="nav-link" [routerLink]='paths.USER_MANAGEMENT' routerLinkActive="active" href="#">
              <i class="fas fa-users-gear fa-lg"></i>
            </a>
          </li>
          <li class="nav-item me-3 me-lg-1">
            <a class="nav-link" [routerLink]='paths.MY_PROFILE' routerLinkActive="active" href="#">
              <i class="fas fa-user fa-lg"></i>
            </a>
          </li>  
          <li class="nav-item me-3 me-lg-1">
            <a class="nav-link" href="#" (click)="logout()">
              <span><i class="fas fa-sign-out-alt fa-lg"></i></span>
            </a>
          </li>
        </ul>
        <!-- Right elements -->
      </div>
    </nav>
    <!-- Navbar -->

    <div class="container">
      <ng-content></ng-content>
    </div>

  </mdb-sidenav-content>
</mdb-sidenav-layout>
