import { Component } from '@angular/core';
import {Store} from "@ngrx/store";
import {getUserDetails} from "./stores/auth/auth.actions";
import {isLoggedIn} from "./stores/auth/auth.selector";
import {Observable} from "rxjs";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'knowledge-based-chatbot';
  constructor() {
  }
}
