import { Injectable, inject } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';
import { filter, tap } from 'rxjs';
import {Location} from '@angular/common';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class HistoryService {
  store = inject(Store);
  private previousRoutes: string[] = [];

  constructor(private router: Router, private location: Location, private route: ActivatedRoute) {
    this.router.events.pipe(
      filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd),
      tap((event: NavigationEnd) => {
        this.previousRoutes = [...this.previousRoutes, event.urlAfterRedirects];
        if (this.previousRoutes.length > 5) {
          this.previousRoutes.shift();
        }
      })
    ).subscribe();
  }

  getLatestRoutes(): string[] {
    return [...this.previousRoutes];
  }

  navigateBack(steps?: number) {
    //entra nell if se non ho una history della navigazione (esempio ho refreshato la pagina)
    if (this.previousRoutes.length < 1) {
      let urlSegments = this.router.url.split('/');
      // se navigate back deve tornare indietro di piu di 1 salto steps sarà > 1 entra nell if altrimenti fa il pop
      if (steps && steps > 1) {
        urlSegments = urlSegments.slice(0, -steps);
      } else if (steps && steps === 1) {
        urlSegments.pop();
      }
      const newUrl = urlSegments.join('/');
      this.router.navigate([newUrl]);
    }
    else this.location.back();
    
  }

}
