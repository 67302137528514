import { createReducer, on } from "@ngrx/store";
import { IAdvancedTableData, IDocument } from "src/app/@shared/components/advanced-table/advanced-table.interface";
import { IResponseApi } from "src/app/interfaces/IApi";
import * as InstancesActions from './instances.actions';
import { Instance, InstanceCreate } from "src/app/@core/services/api/interfaces/api-instance.interface";
import { InstanceStatus } from "src/app/@core/enums/instance.enums";
import { Option } from "src/app/@core/interfaces/generic.interfaces";
export interface InstancesState {
    instances: any;
    error: IResponseApi<any> | null;
    instancesList: IResponseApi<Option[]> | null
    instanceSelected: string | null,
    modelSelected:string| null,
    ModelUpdateError: null,


}

export const initialState: InstancesState = {
    instances: null,
    error: null,
    instancesList: null,
    instanceSelected: null,
    modelSelected:null,
    ModelUpdateError: null,

};

export const instancesReducer = createReducer(
    initialState,
    on(InstancesActions.sendSuccess, (state, { instance }) => ({
        ...state,
        instances: [...state.instances, instance],

    })),
    on(InstancesActions.sendFailure, (state, { error }) => ({
        ...state,
        error: error,
    })),
    on(InstancesActions.requestInstancesSuccess, (state, { instances }) => ({
        ...state,
        instances: instances,
    })),
    on(InstancesActions.requestInstancesError, (state, { error }) => ({
        ...state,
        error: error,
    })),
    on(InstancesActions.requestInstancesListSuccess, (state, { instances }) => ({
        ...state,
        instancesList: instances,
    })),
    on(InstancesActions.setParametersSelected, (state, { instance , model}) => ({
        ...state,
        instanceSelected: instance,
        modelSelected:model,
    })),
    on(InstancesActions.updateModelSucces, (state, { model }) => ({
        ...state,
        modelSelected: model,
        isUpdating: false,
        ModelUpdateError: null,
        instancesList: state.instancesList ? {
            ...state.instancesList,
            data: state.instancesList.data.map(option => 
                option.name === state.instanceSelected
                    ? { ...option, model: model }
                    : option
            )
        } : null,
    })),
    on(InstancesActions.updateModelFailure, (state, { ModelUpdateError }) => ({
        ...state,
        ModelUpdateError: ModelUpdateError,
        isUpdating: false,
    })),
)

