import {createReducer, on} from "@ngrx/store";
import {IResponseApi} from "../../interfaces/IApi";
import * as ChatbotActions from './chatbot.actions';
import {IChat, IMessage} from "../../interfaces/IChat";

export interface ChatbotState {
    chats: IChat[];
    message: IMessage | null;
    error: IResponseApi<any> | null;
}

export const initialState: ChatbotState = {
    chats: [],
    message: null,
    error: null,
};

export const chatbotReducer = createReducer(
    initialState,
    on(ChatbotActions.sendSuccess, (state, {chat}) => ({...state, chats: mergeChat(state.chats, chat)})),
    on(ChatbotActions.sendFailure, (state, {error}) => ({...state, error: error})),
    on(ChatbotActions.sendUserMessage, (state, {message, chatId, sender}) => senderMessage(state, chatId, message, sender)),

    on(ChatbotActions.requestChatSuccess, (state, {chat}) => ({...state, chats: mergeChat(state.chats, chat)})),
    on(ChatbotActions.requestChatFailure, (state, {error}) => ({...state, error: error})),
    on(ChatbotActions.requestChatsSuccess, (state, {chats}) => ({...state, chats})),
    on(ChatbotActions.requestChatsFailure, (state, {error}) => ({...state, error: error})),
)

function mergeChat(chats: IChat[], chat: IChat) {
    const _chats = [...chats];
    const _chat = chat;
    const index = _chats.findIndex((c) => c._id === _chat._id);
    if (index === -1) {
        return [..._chats, _chat];
    }
    _chats[index] = _chat;
    return _chats;
}

function senderMessage(state: ChatbotState, chatId: string, message: string, sender: 'user' | 'bot'){
    const _chats = [...state.chats];
    const index = _chats.findIndex((c) => c._id === chatId);
    if (index === -1) {
        _chats.push({_id: chatId, userId: '', messages: [{message, sender: 'user', created_at: new Date()}]});
        return {...state, chats: _chats};
    }
    if(sender === 'bot'){
        _chats[index].messages.push({message:'...', sender: 'bot', created_at: new Date(), loading: true});
    } else {
        _chats[index].messages.push({message, sender: 'user', created_at: new Date()});
    }
    return {...state, chats: _chats};
}
