import { Directive, OnInit, OnDestroy, Input, ViewContainerRef, TemplateRef } from "@angular/core";
import { Subject, takeUntil } from "rxjs";
import { ERole } from "../enums/role.enums";
import { AuthUserService } from "../services/auth/auth-user.service";


@Directive({
  selector: '[roles]'
})
export class RoleDirective implements OnInit, OnDestroy {

  @Input() roles: ERole[] = [];

  destroy$: Subject<void> = new Subject<void>();

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private auth: AuthUserService
  ) {
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit() {
    this.auth.checkRole(this.roles).pipe(takeUntil(this.destroy$)).subscribe(bool => {
      bool ? this.viewContainerRef.createEmbeddedView(this.templateRef) : this.viewContainerRef.clear();
    })
  }
}
