<div class="row justify-content-center align-items-center vh-100">
    <div class="col-6 d-flex justify-content-center align-items-center wrapper h-50">
        <div class="">
            <form [formGroup]="form" class="d-flex flex-column justify-content-center align-items-center" (submit)="send()">
                <h2 class="m-1 text-center">Confirm your registration</h2>
                <div class="row justify-content-center align-items-center">
                    <div class="col-10 form-outline m-1 ">
                        <input type="password" class="form-control" formControlName="password" />
                        @if(!(form.value.password)){
                        <label class="form-label">Password</label>
                        }
                    </div>

                    <div class="form-outline m-1 col-10">
                        <input type="password" class="form-control" formControlName="confirmPassword" />
                        @if(!(form.value.confirmPassword)){
                        <label class="form-label">Confirm password</label>
                        }
                    </div>

                    <button type="button" (click)="send()" class="btn btn-primary btn-rounded w-75 mt-2" mdbRipple>
                        Sign in
                    </button>

                    @if(form.dirty && form.invalid ){
                        <div class="alert-danger col-10 btn-rounded m-2 p-2 text-center">The passwords are invalid.</div>
                    }
                    @if( (validate==false)){
                        <div class="alert-danger col-10 btn-rounded m-2 p-2 text-center">The passwords are not match.</div>
                    }
                </div>
            </form>
        </div>
    </div>
</div>