import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ILogin} from "./interfaces/api-auth.interface";
import {Observable} from "rxjs";
import {IResponseApi} from "../../../interfaces/IApi";
import {environment} from "../../../../environments/environment";
import {IgetChatbot, IsendChatbot} from "./interfaces/api-chatbot.interface";
import {IChat, IGetIndicesResponse, IMessage} from "../../../interfaces/IChat";

@Injectable({
  providedIn: 'root'
})
export class ApiChatbotService {
  headers = new HttpHeaders()


  constructor(private http: HttpClient) {
  }

  send(data: IsendChatbot): Observable<IResponseApi<IChat>> {
    return this.http.post<IResponseApi<any>>(environment.url_api + '/chatbot/send', data, {headers: this.headers})
  }

  get(data: IgetChatbot): Observable<IResponseApi<IChat>> {
    return this.http.get<IResponseApi<any>>(environment.url_api + '/chatbot/get', {headers: this.headers, params: {chatId: data.chatId}})
  }

  getChats(): Observable<IResponseApi<IChat[]>> {
    return this.http.get<IResponseApi<any>>(environment.url_api + '/chatbot/getChats', {headers: this.headers})
  }
}
