import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { Observable, filter, take, switchMap, iif, of } from "rxjs";
import { getUser } from "src/app/stores/auth/auth.selector";
import { ERole } from "../../enums/role.enums";
import { IUser } from "../api/interfaces/api-auth.interface";


@Injectable({
  providedIn: 'root'
})
export class AuthUserService {
  user$ = this.store.select(getUser)
  user: IUser;

  headers = new HttpHeaders()

  constructor(private http: HttpClient, private store: Store) {
    this.user$.subscribe(user => {
      this.user = user!;
    });
  }

  checkRole(roles: ERole[]): Observable<boolean> {
    return this.store.pipe(
      select(getUser),
      filter(user => user !== null),
      take(1),
      switchMap(user =>
        iif(
          () => !!user?.role,
          of(roles.includes(user?.role as ERole)),
          of(false)
        )
      )
    )
  }

  isViewingOwnProfile(email: string) {
    return this.store.pipe(
      select(getUser),
      filter(user => user !== null),
      take(1),
      switchMap(user => {
        return of(user?.email === email);
      })
    )
  }

  getRole(): ERole | null {
    return this.user?.role as ERole || null;
  }
}
