import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import * as AuthActions from './auth.actions';
import {ApiAuthService} from "../../@core/services/api/api-auth.service";


@Injectable()
export class AuthEffects {
  constructor(private actions$: Actions, private authService: ApiAuthService) {}
  login$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.login),
    mergeMap((action) => this.authService.login({email: action.username, password: action.password})
      .pipe(
        switchMap(user => {
          localStorage.setItem('token', user.data.token);
          return this.authService.getMe();
        }),
        map((user) => {
          return AuthActions.loginSuccess({ user });
        }),
        catchError(error => of(AuthActions.loginFailure({ error: error.error })))
      )
    )
  ));

  getUserDetails$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.getUserDetails),
    mergeMap(() => this.authService.getMe()
      .pipe(
        map(userDetails => AuthActions.getUserDetailsSuccess({ userDetails })),
        catchError(error => of(AuthActions.getUserDetailsFailure()))
      )
    )
  ));
}
