import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import * as ChatbotActions from './chatbot.actions';

import {ApiChatbotService} from "../../@core/services/api/api-chatbot.service";
import {catchError, map, mergeMap} from "rxjs/operators";
import {of, tap} from "rxjs";
import {Store} from "@ngrx/store";

@Injectable()
export class ChatbotEffects {
    send$ = createEffect(() => this.actions$.pipe(
        ofType(ChatbotActions.send),
        tap((action) => {
            this.store.dispatch(ChatbotActions.sendUserMessage({message: action.query, chatId: action.chatId, sender: 'user'}))
            setTimeout(() => {
                this.store.dispatch(ChatbotActions.sendUserMessage({message: '...', chatId: action.chatId, sender: 'bot'}))
            },500)
        }),
        mergeMap((action) => this.chatbotservice.send({index: action.index, query: action.query, chatId: action.chatId})
            .pipe(map(response => {
                    return ChatbotActions.sendSuccess({chat: response.data})
                }),
                catchError(error => of(ChatbotActions.sendFailure({error: error.error})))
            )
        )
    ));
    get$ = createEffect(() => this.actions$.pipe(
        ofType(ChatbotActions.requestChat),
        mergeMap((action) => this.chatbotservice.get({chatId: action.chatId})
            .pipe(
                map(response => {
                    return ChatbotActions.requestChatSuccess({chat: response.data})
                }),
                catchError(error => of(ChatbotActions.requestChatFailure({error: error.error})))
            )
        )
    ));

    getChats$ = createEffect(() => this.actions$.pipe(
        ofType(ChatbotActions.requestChats),
        mergeMap(() => this.chatbotservice.getChats()
            .pipe(
                map(response => {
                    return ChatbotActions.requestChatsSuccess({chats: response.data})
                }),
                catchError(error => of(ChatbotActions.requestChatsFailure({error: error.error})))
            )
        )
    ));

    constructor(private actions$: Actions, private chatbotservice: ApiChatbotService, private store: Store) {
    }
}
