import { createAction, props } from '@ngrx/store';

export const login = createAction('[Auth] Login', props<{ username: string, password: string }>());
export const logout = createAction('[Auth] Logout');

export const resetPassword = createAction('[Auth] Reset Password', props<{ username: string, password: string }>());

export const loginSuccess = createAction('[Auth] Login Success', props<{ user: any }>());
export const loginFailure = createAction('[Auth] Login Failure', props<{ error: any }>());

export const getUserDetails = createAction('[Auth] Get User Details');
export const getUserDetailsSuccess = createAction('[Auth] Get User Details Success', props<{ userDetails: any }>());
export const getUserDetailsFailure = createAction('[Auth] Get User Details Failure');
