import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MdbFormsModule} from "mdb-angular-ui-kit/forms";
import {MdbValidationModule} from "mdb-angular-ui-kit/validation";
import {MdbCheckboxModule} from "mdb-angular-ui-kit/checkbox";
import {MdbNotificationModule} from "mdb-angular-ui-kit/notification";
import {MdbModalModule} from "mdb-angular-ui-kit/modal";
import {MdbSidenavModule} from "mdb-angular-ui-kit/sidenav";
import {MdbCollapseModule} from "mdb-angular-ui-kit/collapse";
import {MdbSelectModule} from "mdb-angular-ui-kit/select";
import {MdbTabsModule} from "mdb-angular-ui-kit/tabs";
import {MdbTableModule} from "mdb-angular-ui-kit/table";
import {MdbScrollbarModule} from "mdb-angular-ui-kit/scrollbar";
import {MdbRippleModule} from "mdb-angular-ui-kit/ripple";
import {MdbDropdownModule} from "mdb-angular-ui-kit/dropdown";

const mdbModules = [
	MdbRippleModule,
	MdbSidenavModule,
	MdbCollapseModule,
	MdbFormsModule,
	MdbNotificationModule,
  MdbModalModule,
  MdbDropdownModule,
  MdbTableModule,
  MdbScrollbarModule,
  MdbCheckboxModule,
  MdbSelectModule,
  MdbTabsModule,
  MdbValidationModule
]

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		...mdbModules
	],
	exports: [
		...mdbModules
	]
})
export class Mdb5Module { }
