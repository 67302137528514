import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import * as InstancesActions from './instances.actions';
import { catchError, map, mergeMap, of, switchMap, tap } from "rxjs";
import { InstanceService } from "src/app/@core/services/api/api-instance.service";
import { ApiChatbotService } from "src/app/@core/services/api/api-chatbot.service";

@Injectable()
export class InstancesEffects {
    send$ = createEffect(() => this.actions$.pipe(
        ofType(InstancesActions.sendInstance),
        mergeMap((action) => this.instacesService.send(action.instance)
            .pipe(
                tap(() => {
                    const loadDataRequest = {
                        pagination: {
                            page: 1,
                            size: 10
                        },
                    }
                    this.store.dispatch(InstancesActions.requestInstances({ request: loadDataRequest }))
                }),
                map(response => {
                    return InstancesActions.sendSuccess({ instance: response })
                }),
                catchError(error => of(InstancesActions.sendFailure({ error: error.error })))
            )
        )
    ))
    updateModel$ = createEffect(() => this.actions$.pipe(
        ofType(InstancesActions.updateModel),
        mergeMap((action) => this.chatbotservice.updateModel(action.chatId, action.model)
            .pipe(
                map(response => {
                    return InstancesActions.updateModelSucces({  model: action.model })
                }),
                catchError(error => of(InstancesActions.updateModelFailure({ ModelUpdateError: error })))
            )
        )
    ))
    getAll$ = createEffect(() => this.actions$.pipe(
        ofType(InstancesActions.requestInstances),
        switchMap((action) => this.instacesService.getAll(action.request).pipe(
            map(response => {

                return InstancesActions.requestInstancesSuccess({ instances: response })
            }),
            catchError(error => of(InstancesActions.requestInstancesError({ error: error.error })))
        ))
    ))
    getList$ = createEffect(() => this.actions$.pipe(
        ofType(InstancesActions.requestInstancesList),
        switchMap((action) => this.instacesService.getList().pipe(
            map(response => {

                return InstancesActions.requestInstancesListSuccess({ instances: response })
            }),
            catchError(error => of(InstancesActions.requestInstancesError({ error: error.error })))
        ))
    ))
    deleteInstance$ = createEffect(() => this.actions$.pipe(
        ofType(InstancesActions.deleteInstance),
        mergeMap((id) => this.instacesService.deleteInstance(id.instanceId).pipe(
            map((response) => {
                const loadDataRequest = {
                    pagination: {
                        page: 1,
                        size: 10
                    },
                }
                return InstancesActions.requestInstances({ request: loadDataRequest })
            })
        ))
    ))
    constructor( private chatbotservice: ApiChatbotService, private actions$: Actions, private store: Store, private instacesService: InstanceService) {
    }

}