import { CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { getUser, isLoggedIn, selectUser } from '../../stores/auth/auth.selector';
import { switchMap, take, filter, map } from 'rxjs/operators';
import { getUserDetails } from '../../stores/auth/auth.actions';
import { of, Observable } from 'rxjs';
import { inject } from '@angular/core';
import { limitedPaths } from 'src/app/@shared/constants/path.constants';
import { ERole } from '../enums/role.enums';

export const authGuard: CanActivateFn = (_, state) => {
  const store = inject(Store);
  const router = inject(Router);

  return store.pipe(
    select(isLoggedIn),
    take(1),
    switchMap(loggedIn => {
      if (loggedIn) {
        // L'utente è già loggato
        return of(true);
      }

      // L'utente non è loggato, controlla se è presente il token
      if (!localStorage.getItem('token')) {
        if (state.url.includes('login')) {
          return of(true);
        } else {
          router.navigate(['/login']);
          return of(false);
        }
      }

      // L'utente non è loggato, ma il token è presente dispaccia getUserDetails
      store.dispatch(getUserDetails());

      // Aspetta finché l'azione getUserDetailsSuccess o getUserDetailsFailure non è stata emessa
      return store.pipe(
        select(selectUser),
        filter(user => user !== null),
        take(1),
        switchMap(user => {
          if (user) {
            if (state.url.includes('login')) {
              router.navigate(['/dashboard']);
            }
            return checkRoleAndRedirect(state, router, store)
          } else {
            if (state.url.includes('login')) {
              return of(true);
            } else {
              router.navigate(['/login']);
              return of(false);
            }
          }
        })
      );

      function checkRoleAndRedirect(state: RouterStateSnapshot, router: Router, store: Store) {
        const user = store.select(getUser)
        return user.pipe(map(user => {
          if (user) {
            const actualPath = limitedPaths.find(path => path.absolutePath === state.url);

            if (actualPath && !actualPath.roles.includes(user.role as ERole)) {
              router.navigate(['/dashboard']);
              return false;
            }
            return true
          } else {
            router.navigate(['/login']);
            return false
          }
        }))
      }
    })
  );
};
